<template>
  <div>
    <div class="mb-6">
      <div class="items-grid-view vx-row match-height">
        <div class="vx-col lg:w-1/3 sm:w-1/2 w-full">
          <vx-card
            class="grid-view-item overflow-hidden"
            title="Les particuliers"
            title-color="#fff"
            content-color="#fff"
            :card-background="'linear-gradient(120deg ,rgba(0,84,139,0.5), rgba(0,84,139,.8)), url(' + card_bg_img + ')'"
          >
            <div>
              <div class="item-details px-4">
                <vs-list>
                  <vs-list-header class="mt-2 aab-vie-category-product" title="Prévoyance" color="#fff"></vs-list-header>
                  <div class="aab-vie-product mb-2" @click="open('ABQ')">- {{ getProduct("ABQ") }}</div>
                  <div class="aab-vie-product mb-2" @click="open('APF')">- {{ getProduct("APF") }}</div>
                  <div class="aab-vie-product mb-2" @click="open('AAF')">- {{ getProduct("AAF") }}</div>
                  <vs-list-header class="mt-2 aab-vie-category-product" title="Études" color="#fff"></vs-list-header>
                  <div class="aab-vie-product mb-2" @click="open('AAE')">- {{ getProduct("AAE") }}</div>
                  <vs-list-header class="mt-2 aab-vie-category-product" title="Retraite" color="#fff"></vs-list-header>
                  <div class="aab-vie-product mb-2" @click="open('AHR')">- {{ getProduct("AHR") }}</div>
                </vs-list>
              </div>
            </div>
          </vx-card> 
        </div>
        <div class="vx-col lg:w-1/3 sm:w-1/2 w-full">
          <vx-card
            class="grid-view-item overflow-hidden"
            title="Les professionnels"
            title-color="#fff"
            content-color="#fff"
            :card-background="'linear-gradient(120deg ,rgba(0,153,68,0.5), rgba(0,153,68,.8)), url(' + card_bg_img + ')'"
          >
            <div>
              <div class="item-details px-4">
                <vs-list>
                  <vs-list-header class="mt-2 aab-vie-category-product" title="Prévoyance" color="#fff"></vs-list-header>
                  <div class="aab-vie-product mb-2" @click="open('ABQ')">- {{ getProduct("ABQ") }}</div>
                  <div class="aab-vie-product mb-2" @click="open('APF')">- {{ getProduct("APF") }}</div>
                  <div class="aab-vie-product mb-2" @click="open('AAF')">- {{ getProduct("AAF") }}</div>
                  <vs-list-header class="mt-2 aab-vie-category-product" title="Études" color="#fff"></vs-list-header>
                  <div class="aab-vie-product mb-2" @click="open('AAE')">- {{ getProduct("AAE") }}</div>
                  <vs-list-header class="mt-2 aab-vie-category-product" title="Retraite" color="#fff"></vs-list-header>
                  <div class="aab-vie-product mb-2" @click="open('AHR')">- {{ getProduct("AHR") }}</div>
                </vs-list>
              </div>
            </div>
          </vx-card>
        </div>
        <div class="vx-col lg:w-1/3 sm:w-1/2 w-full">
          <vx-card
            class="grid-view-item overflow-hidden"
            title="Les entreprises"
            title-color="#fff"
            content-color="#fff"
            :card-background="'linear-gradient(120deg ,rgba(226,14,28,0.5), rgba(226,14,28,.8)), url(' + card_bg_img + ')'"
          >
            <div>
              <div class="item-details px-4">
                <vs-list>
                  <vs-list-header class="mt-2 aab-vie-category-product" title="Prévoyance" color="#fff"></vs-list-header>
                  <div class="aab-vie-product mb-2" @click="open('ABQ')">- {{ getProduct("ABQ") }}</div>
                  <div class="aab-vie-product mb-2" @click="open('APF')">- {{ getProduct("APF") }}</div>
                  <div class="aab-vie-product mb-2" @click="open('AAF')">- {{ getProduct("AAF") }}</div>
                  <vs-list-header class="mt-2 aab-vie-category-product" title="Études" color="#fff"></vs-list-header>
                  <div class="aab-vie-product mb-2" @click="open('AAE')">- {{ getProduct("AAE") }}</div>
                  <vs-list-header class="mt-2 aab-vie-category-product" title="Retraite" color="#fff"></vs-list-header>
                  <div class="aab-vie-product mb-2" @click="open('AHR')">- {{ getProduct("AHR") }}</div>
                </vs-list>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin } from "@/mixins/mixin";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      card_bg_img: require("@/assets/images/pages/card-bg-demo.jpg")
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("general", ["allProduct"]),
  },
  watch: {},
  methods: {
    getProduct(productId) {
      return this.allProduct.find(product => product.id == productId).label;
    },
    open(productID) {
      let url = `https://demo-tarification.aabvie.net/landing/${productID.toLowerCase()}?type=${this.user.type}&id=${this.user.id}&productID=${productID}&fromOrigin=broker`
      window.open(url, "_blank");
    }
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.aab-vie-category-product {
  font-size: 1.4rem !important;
}
.vs-list--header {
    -webkit-box-shadow: 0px 7px 7px -5px rgba(255,254,254, 0.35) !important;
    box-shadow: 0px 7px 7px -5px rgba(255,254,254, 0.35) !important;
}
.aab-vie-product {
  margin-left: 0% !important;
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
}
.grid-view-item {
  .grid-view-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    transition: 0.45s;
  }
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
